<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/patient'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Panel Name <strong>*</strong></label
                      >
                      <input
                        v-model="formData.name"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Slug <strong>*</strong></label>
                      <input
                        v-model="formData.slug"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <b-form-checkbox
                      v-model="formData['type']"
                      unchecked-value="2"
                      value="1"
                      class="mb-3"
                      checked
                      plain
                      >Is it a long image?</b-form-checkbox
                    >
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Image <strong>*</strong></label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('img', $event)"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Image Sub Text <strong>*</strong></label
                      >
                      <input
                        v-model="formData['img_sub_text']"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="seoKeyword">Top Category</label>

                      <multiselect
                        v-model="selectedOption"
                        :options="cats"
                        class="helo"
                        placeholder="Category Name"
                        tag-placeholder="Add this as new tag"
                        label="name"
                        track-by="id"
                        @input="updateCategoryId"
                      >
                        <template slot-scope="{ values }"
                          ><span>{{ values.name }} </span></template
                        >
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import Multiselect from "vue-multiselect";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "Category Update",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Category Update",
      items: [
        {
          text: "Category",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {},
      value1: null,
      options: [],
      cats: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    updateCategoryId(selectedOption) {
      this.formData.category_id = selectedOption ? selectedOption.id : null;
    },
    async FindLp() {
      await API.Get(API.Patient.UpdateList + this.$route.params.id).then(
        (response) => {
          console.log(response.data);
          if (response.data.status == "success") {
            this.selectedOption = response.data.order.category_id;
            this.formData = response.data.order;
            this.cats = response.data.cats;
          } else {
            this.cats = [];
          }
        }
      );
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Patient.Update + this.$route.params.id,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/patient");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
